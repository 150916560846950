import { red, green, darkOrange, yellow, berry, lightGreen, marigold, darkRed, cranberry, pumpkin, peach, gold, brass, brown, forest, seafoam, darkGreen, lightTeal, teal, steel, blue, royalBlue, cornflower, navy, lavender, purple, grape, lilac, pink, magenta, plum, beige, mink, platinum, anchor } from './colors';
export const statusSharedColors = {
    red,
    green,
    darkOrange,
    yellow,
    berry,
    lightGreen,
    marigold
};
export const personaSharedColors = {
    darkRed,
    cranberry,
    pumpkin,
    peach,
    gold,
    brass,
    brown,
    forest,
    seafoam,
    darkGreen,
    lightTeal,
    teal,
    steel,
    blue,
    royalBlue,
    cornflower,
    navy,
    lavender,
    purple,
    grape,
    lilac,
    pink,
    magenta,
    plum,
    beige,
    mink,
    platinum,
    anchor
};
